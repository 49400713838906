.navbar {
}

.navbar:global(.ui.menu) {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.navbar:global(.menu .item:before) {
  background: none !important;
}

.navbar :global(.ui.menu.visible) {
  z-index: 101 !important;
}

.logo {
  padding-top: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-bottom: 0px !important;
  background: #ff5252 !important;
}

.mobileDropdownMenu {
  max-height: calc(100vh - 60px);
  overflow-y: scroll;
}
