.categoryResults {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.8em;
}

.categoryHeader {
  padding: 10px 15px;
}

.searchBar :global(.category) {
  display: flex !important;
}
